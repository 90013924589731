<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="项目">
          <el-select clearable style="width: 120px" filterable v-model="searchData.project_contract_id" placeholder="合同">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.project.name + item.remark + ' ' + item.start_at +'~'+ item.end_at">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提成人">
          <el-select clearable style="width: 120px" filterable v-model="searchData.person_id" placeholder="提成人">
            <el-option
                v-for="(item, key) in person"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
              v-model="searchData.date"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable style="width: 120px" v-model="searchData.status" placeholder="状态">
            <el-option label="未结算" :value="1"></el-option>
            <el-option label="已结算" :value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTcDetail()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="success" @click.stop="clickCreate()">添加明细</el-button>
          <el-button type="info" @click.stop="clickComplete()">结算</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" column-key="id" ref="detail.table" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column type="selection" width="40" :selectable="selectableData"/>
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="contract_name" label="合同"/>
      <el-table-column prop="person_name" label="提成人"/>
      <el-table-column prop="date" label="提成日期"/>
      <el-table-column prop="amount" label="提成金额"/>
      <el-table-column prop="" label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.status === 1 ? 'info' : 'success'" v-text="scope.row.status_desc"></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="clickDestroy(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTcDetail()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑提成" fullscreen append-to-body>
      <el-form
          ref="detail"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"

      >
        <el-form-item label="项目" prop="project_id">
          <el-select clearable style="width: 120px" filterable v-model="formData.data.project_contract_id" placeholder="项目">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.project.name + item.remark + ' ' + item.start_at +'~'+ item.end_at">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提成人" prop="person_id">
          <el-select clearable style="width: 120px" filterable v-model="formData.data.person_id" placeholder="提成人">
            <el-option
                v-for="(item, key) in person"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker v-model="formData.data.date" value-format="YYYY-MM-DD" type="date"
                          placeholder="实付时间"/>
        </el-form-item>
        <el-form-item label="金额" prop="amount">
          <el-input-number v-model="formData.data.amount" placeholder="填写比例，或固定金额" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.data.remark" placeholder="备注信息，必填"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
  import { apiGetProjectContracts } from '@/api/projectContractApi'
  import {apiGetTcDetailIndex, apiGetTcPersonIndex, apiUpdateTcDetail, apiCreateTcDetail, apiDestroyTcDetail, apiCompleteTcDetail} from "@/api/tcApi"
  import {getCompanyId} from "@/plugins/company"

  let formData = {
    company_id: getCompanyId(),
    id: 0,
    project_contract_id: '',
    person_id: '',
    date: '',
    amount: '',
    remark: '',
  }

  export default {
    created() {
      this.getProject()
      this.getTcPerson()
      this.getTcDetail()
    },
    data() {
      return {
        searchData:{
          status: 1,
          page: 1,
          per_page: 15,
          company_id: getCompanyId(),
        },
        formData:{
          lock: false,
          data: formData,
          rules: {
            project_contract_id: [{required: true, message: '请选择关联项目', trigger: 'blur'}],
            person_id: [{required: true, message: '请选择结算人', trigger: 'blur'}],
            date: [{required: true, message: '请选择结算规则', trigger: 'blur'}],
            amount: [{required: true, message: '请填写数值', trigger: 'blur'}],
          },
          show: false,
        },
        pageData:{
          total: 0
        },
        projects:[],
        person: [],
        tableData:[],
      }
    },
    methods:{
      getTcDetail () {
        apiGetTcDetailIndex(this.searchData).then(res =>{
          this.pageData = res.meta
          this.tableData = res.data
        })
      },
      getProject () {
        apiGetProjectContracts({company_id: getCompanyId(), status:1, per_page: 200, with_query: ['project']}).then(res =>{
          this.projects = res.data
        })
      },
      getTcPerson() {
        apiGetTcPersonIndex({status:1, per_page: 200}).then(res =>{
          this.person = res.data
        })
      },
      clickCreate () {
        this.formData.show = true
        this.formData.data = formData
      },
      // 点击更新
      clickUpdate (row) {
        this.formData.data = row
        this.formData.show = true
      },
      clickDestroy(row){
        this.$confirm('确定删除吗').then(() => {
          apiDestroyTcDetail(row.id).then(() => {
            this.getTcDetail()
            this.$message.success('删除成功')
          })
        })
      },
      onSubmit()  {
        if (this.formData.lock) {return;}
        this.formLock()
        this.$refs['detail'].validate(valid => {
          if (valid) {
            // 更新逻辑
            if (this.formData.data.id > 0) {
              apiUpdateTcDetail(this.formData.data.id, this.formData.data).then(() =>{
                this.$message.success('修改成功')
                this.getTcDetail()
                this.closeDialog()
                this.formUnlock()
              })
            } else {
              apiCreateTcDetail(this.formData.data).then(() => {
                this.$message.success('创建成功')
                this.getTcDetail()
                this.closeDialog()
                this.formUnlock()
              }).catch(() => {
                this.formUnlock()
              })
            }
          }
        })
      },
      clickComplete() {
        let rows = this.$refs['detail.table'].getSelectionRows();

        if (!rows.length) {
          this.$message.warning('请先选择内容')
          return
        }

        let ids = []
        rows.forEach((item) => {
          ids.push(item.id)
        })

        this.$confirm('是否需要结算至余额').then(() =>{
          apiCompleteTcDetail({ids: ids, settle: 1}).then(() =>{
            this.$message.success('操作成功，已结算至余额')
            this.getTcDetail()
          })
        }).catch(() =>{
          apiCompleteTcDetail({ids: ids}).then(() =>{
            this.$message.success('操作成功，未结算至余额')
            this.getTcDetail()
          })
        })
      },
      selectableData (row) {
        return row.status === 1
      },
      // 关闭弹窗
      closeDialog() {
        this.formData.show = false
      },
      formLock() {
        this.formData.lock = true
      },
      formUnlock() {
        this.formData.lock = false
      },
    }
  }
</script>
